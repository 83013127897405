<template>
  <div class="container top-space-to-boxes">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="guide">
          <H1>Screenleap</H1>
          <a target="_blank" href="https://www.screenleap.com/">Tryck på denna länk för att öppna Screeleaps hemsida </a>
          <div class="image-text-container pt-5">
            <img class="share-your-screen" alt="Share your screen" src="../../assets/images/screenleap/share-your-screen.jpg" />
            <p>Tryck på knappen “share your screen now”</p>
          </div>
          <div class="image-text-container">
            <img class="drag-left-audio" alt="Drag left audio" src="../../assets/images/screenleap/drag-left-audio.jpg" />
            <p>Tryck och dra till vänster för att inaktivera audio</p>
          </div>
          <div class="image-text-container">
            <img class="confirm-audio" alt="Confirm audio" src="../../assets/images/screenleap/confirm-audio.jpg" />
            <p>Tryck på Confirm audio</p>
          </div>
          <div class="image-text-container">
            <img class="press-picture" alt="Press picture" src="../../assets/images/screenleap/press-picture.jpg" />
            <p>Tryck på bilden. Tryck sedan på knappen Dela.</p>
          </div>
          <div class="image-text-container pb-5">
            <img class="share-code" alt="Share code" src="../../assets/images/screenleap/share-code.jpg" />
            <p>Säg koden i ”Share code” till den du vill dela din skärm med.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../../src/styles/_layout.scss";
import "../../../src/styles/_guide.scss";

export default {
  name: "ScreenLeap",
};
</script>
